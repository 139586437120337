@font-face {
  font-family: "Matter-Regular";
  src:
    url("./assets/fonts/Matter/Matter-Regular.woff2") format("woff2"),
    url("./assets/fonts/Matter/Matter-Regular.woff") format("woff"),
    url("./assets/fonts/Matter/Matter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Matter-SemiBold";
  src:
    url("./assets/fonts/Matter/Matter-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Matter/Matter-SemiBold.woff") format("woff"),
    url("./assets/fonts/Matter/Matter-SemiBold.ttf") format("truetype");
}

/*TODO remove once MUI fix the bug*/
textarea {
  font-family: "Matter-Regular";
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
  margin: auto;
}

.react-pdf__Page {
  width: 100% !important;
  height: 100% !important;
  min-width: auto !important;
  min-height: auto !important;
  margin: auto;
}
